import useRotatable, {
  RotatableOptions
} from "@/components/util/form/useRotatable"
import React, { ChangeEvent, FormEvent, useEffect } from "react"
import Icons from "../Icon"
import data from "./data.json"
import style from "./style.scss"

const getQuadrant = (
  quadrants: any,
  progress: number,
  type: "colour" | "progress"
) => {
  const quadrantSize =
    type === "progress" ? 100 / quadrants.length : 100 / (quadrants.length - 1)

  const newQuadrants = quadrants.map((quadrant: any, index: number) => {
    return {
      ...quadrant,
      progress: quadrantSize * (index + 1),
      colour: {
        ...quadrant.colour,
        position: quadrantSize * index
      }
    }
  })

  const quadrantIndex = newQuadrants.findIndex((quadrant: any) => {
    return type === "progress"
      ? progress <= quadrant.progress
      : progress < quadrant.colour.position
  })

  return {
    quadrantIndex,
    newQuadrants
  }
}

const getColourForPercentage = (quadrants: any, progress: number) => {
  const { quadrantIndex, newQuadrants } = getQuadrant(
    quadrants,
    progress,
    "colour"
  )

  if (quadrantIndex === -1) {
    return
  }

  const lower = { ...newQuadrants[quadrantIndex - 1] }
  const upper = { ...newQuadrants[quadrantIndex] }

  const range = upper.colour.position - lower.colour.position
  const rangeProgress = (progress - lower.colour.position) / range
  const progressLower = 1 - rangeProgress
  const progressUpper = rangeProgress
  const colour = {
    r: Math.floor(
      lower.colour.r * progressLower + upper.colour.r * progressUpper
    ),
    g: Math.floor(
      lower.colour.g * progressLower + upper.colour.g * progressUpper
    ),
    b: Math.floor(
      lower.colour.b * progressLower + upper.colour.b * progressUpper
    )
  }

  return "rgb(" + [colour.r, colour.g, colour.b].join(",") + ")"
}

interface Quadrant {
  colour: {
    r: number
    g: number
    b: number
  }
  title: string
}

interface DialProps {
  emotion: string
  name?: string
  disabled?: boolean
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

const Dial: React.SFC<Readonly<DialProps>> = (props) => {
  const { emotion, onChange } = props
  const dialRef = React.createRef<any>()
  const inputRef = React.createRef<any>()

  const quadrants = (data as any).titles[emotion].map(
    (title: string, index: number) => {
      return {
        colour: (data as any).colour[index],
        title
      } as Quadrant
    }
  )

  const defaultSettings = {
    ref: dialRef,
    disabled: props.disabled || false,
    angleOptions: {
      defaultAngle: -35,
      offset: -35,
      constraints: {
        min: -35,
        max: -145
      }
    }
  } as RotatableOptions

  const { onDragStart, onDragEnd, onDrag, angle, progress } = useRotatable(
    defaultSettings
  )

  const { quadrantIndex, newQuadrants } = getQuadrant(
    quadrants,
    progress,
    "progress"
  )

  const currentQuadrant = newQuadrants[quadrantIndex]

  useEffect(() => {
    inputRef.current.dispatchEvent(new Event("input", { bubbles: true }))
  }, [progress])

  return (
    <section
      className={style.wrapper}
      style={{
        backgroundImage: `linear-gradient(0, ${getColourForPercentage(
          quadrants,
          progress
        )}, transparent)`
      }}
    >
      <header className={style.header}>
        <span className={style.instructions}>
          Move the dial to show how strongly you feel
        </span>
        <h1 className={style.title}>{currentQuadrant!.title}</h1>
      </header>

      <div
        ref={dialRef}
        className={style.dial}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        onMouseMove={onDrag}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
        onTouchMove={onDrag}
      >
        <div
          className={style.rotator}
          style={{ transform: `rotate(${angle}deg)` }}
        >
          <div className={style.nub} />
        </div>

        <div className={style.range}>
          <Icons.EmotionIndicator className={style.emotionIndicator} />
        </div>
      </div>

      <input
        type="text"
        ref={inputRef}
        className={style.input}
        value={Math.round(progress)}
        onInput={onChange}
        name={props.name}
        readOnly
      />
    </section>
  )
}

export default Dial
