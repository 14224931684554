import classNames from "classnames"
import { useLayoutEffect, useRef, useState } from "react"
import style from "./style.scss"

export interface StepProps {
  active: boolean
  className?: string
  durationMs?: number
  transitionIn?: StepTransition
  transitionOut?: StepTransition
  customTransitionClass?: string
}

export enum StepTransition {
  SLIDE_BOTTOM = "slide-bottom",
  SLIDE_RIGHT = "slide-right",
  SLIDE_LEFT = "slide-left",
  SLIDE_TOP = "slide-top",
  FADE = "fade",
  DELAY = "delay",
  NONE = "none"
}

enum State {
  INACTIVE = "inactive",
  START_DEACTIVATING = "start-deactivating",
  DEACTIVATING = "deactivating",
  ACTIVE = "active",
  START_ACTIVATING = "start-activating",
  ACTIVATING = "activating"
}

const Step: React.SFC<Readonly<StepProps>> = ({
  active,
  className,
  children,
  durationMs = 250,
  customTransitionClass,
  transitionIn = StepTransition.NONE,
  transitionOut = StepTransition.NONE
}) => {
  const [lastToggled, setLastToggled] = useState<Date>()
  const [state, setState] = useState<State>()
  const transitionRef = useRef<any>()

  const activate = () => {
    if (!lastToggled) {
      setLastToggled(new Date())
      setState(State.ACTIVE)
      return
    }

    switch (state) {
      case State.ACTIVATING:
      case State.ACTIVE:
        return

      case State.DEACTIVATING:
      case State.INACTIVE:
        setState(State.START_ACTIVATING)
        return
    }
  }

  const deactivate = () => {
    if (!lastToggled) {
      setLastToggled(new Date())
      setState(State.INACTIVE)
      return
    }

    switch (state) {
      case State.DEACTIVATING:
      case State.INACTIVE:
        return

      case State.ACTIVATING:
      case State.ACTIVE:
        setState(State.START_DEACTIVATING)
        return
    }
  }

  const handleStartActivating = () => {
    if (transitionIn === StepTransition.NONE) {
      setState(State.ACTIVE)
      return
    }

    setTimeout(() => setState(State.ACTIVATING), 1)
  }

  const handleStartDeactivating = () => {
    if (transitionOut === StepTransition.NONE) {
      setState(State.INACTIVE)
      return
    }

    setTimeout(() => setState(State.DEACTIVATING), 1)
  }

  const setActive = () => {
    setState(State.ACTIVE)
  }

  const setInactive = () => {
    setState(State.INACTIVE)
  }

  useLayoutEffect(() => {
    if (active) {
      activate()
    } else {
      deactivate()
    }
  }, [active])

  useLayoutEffect(() => {
    if (state === State.START_DEACTIVATING) {
      handleStartDeactivating()
    }

    if (state === State.START_ACTIVATING) {
      handleStartActivating()
    }

    if (state === State.ACTIVATING) {
      transitionRef.current.addEventListener("transitionend", setActive)
    }

    if (state === State.DEACTIVATING) {
      transitionRef.current.addEventListener("transitionend", setInactive)
    }

    return () => {
      transitionRef.current.removeEventListener("transitionend", setActive)
      transitionRef.current.removeEventListener("transitionend", setInactive)
    }
  }, [state])

  let transitionClass: string | null = null

  switch (state) {
    case State.START_ACTIVATING:
      transitionClass = `${transitionIn}-inactive`
      break
    case State.ACTIVATING:
      transitionClass = `${transitionIn}-active`
      break
    case State.ACTIVE:
      transitionClass = `active`
      break
    case State.START_DEACTIVATING:
      transitionClass = `${transitionOut}-active`
      break
    case State.DEACTIVATING:
      transitionClass = `${transitionOut}-inactive`
      break
    case State.INACTIVE:
      transitionClass = `inactive`
      break
  }

  return (
    <div className={classNames(style.step, style[`step--${state}`], className)}>
      <div
        style={{ transitionDuration: `${durationMs}ms` }}
        ref={transitionRef}
        className={classNames(
          customTransitionClass,
          style.transition,
          style[`transition--${transitionClass}`]
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default Step
