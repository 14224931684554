import Button from "@/components/Button"
import ChoiceSlider from "@/components/ChoiceSlider"
import choiceSliderData from "@/components/ChoiceSlider/data.json"
import Dial from "@/components/Dial"
import Dots from "@/components/Dots"
import MediaCapture from "@/components/MediaCapture"
import Step from "@/components/Step"
import { compare } from "@/components/util"
import { SliderItem as SIProps } from "@/components/util/useSlider"
import { StylesProps } from "@/types/props"
import classNames from "classnames"
import Link from "next/link"
import React from "react"

export interface SliderItemActionProps {
  type: "NextSlide" | "Link"
  text: string
}

export interface SliderItemProps extends SIProps {
  dots: boolean
  active: boolean
  activeStep?: number
  style: StylesProps
  setNextSlide: () => void
  handleTouchStart: (event: any) => void
  handleTouch: (event: any) => void
}

const defaultProps = {
  size: "Large"
}

const ImageNode: React.SFC<Readonly<any>> = (props) => {
  const { sliderProps, image } = props

  return (
    <>
      {typeof image.type === "undefined" ||
        (image.type === "image" && (
          <figure
            className={classNames(
              sliderProps.style.image,
              sliderProps.style[`image--size${image.size || defaultProps.size}`]
            )}
          >
            <img src={image.url} alt={image.alt} />
          </figure>
        ))}

      {image.type === "background" && (
        <div
          className={classNames(
            sliderProps.style.imageBackground,
            sliderProps.style[
              `imageBackground--size${image.size || defaultProps.size}`
            ]
          )}
          style={{ backgroundImage: `url(${image.url})` }}
        >
          <span>{image.alt}</span>
        </div>
      )}
    </>
  )
}

const TextNode: React.SFC<Readonly<any>> = (props) => {
  const { sliderProps, body } = props

  return <div className={sliderProps.style.text}>{body}</div>
}

const ActionNode: React.SFC<Readonly<any>> = (props) => {
  const { actions, sliderProps } = props

  return actions.map((action: any, index: number) => (
    <React.Fragment key={index}>
      {action.type === "NextSlide" && (
        <Button
          type="button"
          label={action.text}
          className={sliderProps.style.action}
          onClick={() => sliderProps.setNextSlide()}
        />
      )}

      {action.type === "Link" && (
        <Link href={action.url} prefetch={true}>
          <Button
            href={action.url}
            tagName="a"
            label={action.text}
            className={sliderProps.style.action}
          />
        </Link>
      )}
    </React.Fragment>
  ))
}

const ContentNode: React.SFC<Readonly<any>> = (props) => {
  const { component, data, sliderProps } = props

  return (
    <>
      {component === "Image" && (
        <ImageNode {...data} sliderProps={sliderProps} />
      )}

      {component === "Text" && <TextNode {...data} sliderProps={sliderProps} />}

      {component === "Action" && (
        <ActionNode {...data} sliderProps={sliderProps} />
      )}

      {component === "Dial" && (
        <div className={sliderProps.style.dial}>
          <Dial
            emotion="happy"
            onChange={(event: React.ChangeEvent<any>) => {
              if (data && data.compare && sliderProps.active) {
                if (
                  compare(
                    data.compare.operator,
                    parseInt(data.compare.value, 10),
                    parseInt(event.target.value, 10)
                  )
                ) {
                  sliderProps.setNextSlide()
                }
              }
            }}
            disabled={(() => {
              if (data && data.disable) {
                return compare(
                  data.disable.operator,
                  data.disable.value,
                  sliderProps.activeStep
                )
              }

              return false
            })()}
          />
        </div>
      )}

      {component === "MediaCapture" && (
        <MediaCapture
          onChange={() => {
            sliderProps.setNextSlide()
          }}
        />
      )}

      {component === "ChoiceSlider" && (
        <div className={sliderProps.style.choiceSlider}>
          <ChoiceSlider
            slides={choiceSliderData}
            dots={false}
            handleChange={(event: any) => {
              if (data && data.compare && sliderProps.active) {
                if (
                  compare(
                    data.compare.operator,
                    data.compare.value,
                    event.target.value
                  )
                ) {
                  sliderProps.setNextSlide()
                }
              }
            }}
            disabled={(() => {
              if (data && data.disable) {
                return compare(
                  data.disable.operator,
                  data.disable.value,
                  sliderProps.activeStep
                )
              }

              return false
            })()}
          />
        </div>
      )}
    </>
  )
}

const SliderItem: React.SFC<Readonly<SliderItemProps>> = (props) => {
  const {
    active,
    dots,
    style,
    description,
    content,
    activeStep,
    handleTouchStart,
    handleTouch
  } = props

  return (
    <div
      className={classNames(
        style.sliderItem,
        style[`sliderItem--step${activeStep}`],
        {
          [style["sliderItem--active"]]: active
        }
      )}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouch}
    >
      {content && (
        <div className={style.content}>
          {content.map((component, index) => {
            return (
              <div className={style.node} key={index}>
                <ContentNode {...component} sliderProps={props} />
              </div>
            )
          })}
        </div>
      )}

      {description && (
        <div className={style.description}>
          {description.map((item) => {
            return item.content.map((component: any, index: any) => (
              <div
                className={style.node}
                data-step={item.step || 0}
                key={index}
              >
                <ContentNode {...component} sliderProps={props} />
              </div>
            ))
          })}
        </div>
      )}

      {dots && (
        <div className={style.dots}>
          <Dots active={props.index} length={3} />
        </div>
      )}
    </div>
  )
}

export default SliderItem
