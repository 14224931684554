import Slider from "@/components/Slider"
import React from "react"
import style from "./style.scss"

interface IntroSliderProps {
  slides: any
}

const IntroSlider: React.SFC<Readonly<IntroSliderProps>> = (props) => {
  return (
    <div className={style.introSlider}>
      {props.slides && (
        <Slider
          title="Introduction slides"
          slides={props.slides}
          speed={5000}
          autoplay={true}
          dots={true}
          style={style}
          draggable={true}
        />
      )}
    </div>
  )
}

export default IntroSlider
