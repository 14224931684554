export enum Gender {
  MALE = "male",
  FEMALE = "female",
  NA = "na"
}

export interface User {
  id?: string
  type?: "public" | "private"
  dateOfBirth?: Date
  email: string
  gender: Gender
  livingSituation: string
  name: string
  occupation: string
  phone: string
  postcode: string
  profilePic: string | File | undefined
}

export interface UserState {
  loggedIn: boolean
  accessToken: string | null
  user: User | null
}
