import { AppState } from "@/store/reducers"
import { Gender, User } from "@/store/user/models"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Slider, { Settings } from "react-slick"
import ChoiceSliderItem from "./ChoiceSliderItem"
import style from "./style.scss"

interface ChoiceSliderProps {
  slides: any
  user?: User | null
  name?: string
  disabled?: boolean
  handleChange?: (value: any) => void
}

const ChoiceSlider: React.SFC<Readonly<ChoiceSliderProps & Settings>> = (
  props
) => {
  const { slides, handleChange, name, ...sliderSettings } = props
  const sliderRef = useRef<any>()
  const inputRef = useRef<any>()
  const [sliderValue, setSliderValue] = useState<string>("happy")
  const [gender, setGender] = useState<Gender>(Gender.MALE)

  useEffect(() => {
    inputRef.current.dispatchEvent(new Event("input", { bubbles: true }))
  }, [sliderValue])

  const defaultSettings = {
    dots: true,
    speed: 300,
    swipe: true
  } as Settings

  const mandatorySettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "25%",
    infinite: false,
    arrows: false,
    touchThreshold: 15,
    swipe: !props.disabled || false,
    afterChange: (index: number) => {
      setSliderValue(slides[index].value)
    }
  } as Settings

  const settings = {
    ...defaultSettings,
    ...sliderSettings,
    ...mandatorySettings
  } as Settings

  const getImageGender = () => {
    const userGender = props.user ? props.user.gender : Gender.NA

    if (userGender === Gender.MALE || userGender === Gender.FEMALE) {
      return userGender
    }

    return [Gender.MALE, Gender.FEMALE][Math.round(Math.random())]
  }

  useEffect(() => {
    setGender(getImageGender())
  }, [])

  return (
    <section className={style.choiceSlider}>
      <Slider ref={sliderRef} {...settings}>
        {slides &&
          slides.map((slide: any, index: number) => {
            return (
              <ChoiceSliderItem
                title={slide.title}
                image={slide[`${gender}_image`]}
                key={index}
              />
            )
          })}
      </Slider>

      <input
        className={style.input}
        type="text"
        readOnly
        value={sliderValue}
        name={name}
        ref={inputRef}
        onInput={handleChange}
      />
    </section>
  )
}

export default connect(
  (state: AppState) => ({
    user: state.user.user
  }),
  null
)(ChoiceSlider)
