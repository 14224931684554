import IntroSlider from "@/components/IntroSlider"
import IntroSliderData from "@/components/IntroSlider/data.json"
import Layout from "@/components/Layout"
import SplashScreen from "@/components/SplashScreen"
import Step, { StepTransition } from "@/components/Step"
import { withNoAuthentication } from "@/lib/withAuthentication"
import { mapDispatchToProps } from "@/lib/withReduxStore"
import { NextPage } from "next"
import Head from "next/head"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import style from "./style.scss"

interface PageProps {}

enum Steps {
  Splash = 1,
  Slider = 2
}

const Index: NextPage<PageProps> = (props) => {
  const [step, setStep] = useState<Steps>(Steps.Splash)

  useEffect(() => {
    setTimeout(() => {
      setStep(Steps.Slider)
    }, 4000)
  }, [])

  return (
    <>
      <Head>
        <title>Black Mirror</title>
        <meta name="description" content="meta" />
      </Head>

      <Layout className={style.layout}>
        <Step
          className={style.step}
          active={step === Steps.Splash}
          transitionOut={StepTransition.DELAY}
        >
          <SplashScreen />
        </Step>

        <Step
          className={style.step}
          active={step === Steps.Slider}
          transitionIn={StepTransition.SLIDE_RIGHT}
        >
          {step === Steps.Slider && <IntroSlider {...IntroSliderData} />}
        </Step>
      </Layout>
    </>
  )
}

Index.getInitialProps = async ({
  reduxStore,
  query
}: any): Promise<PageProps> => {
  return query
}

export default withNoAuthentication(
  connect(
    null,
    mapDispatchToProps
  )(Index)
)
