import classNames from "classnames"
import React from "react"
import style from "./style.scss"

interface DotsProps {
  length: number
  active: number
}

const Dots: React.SFC<Readonly<DotsProps>> = (props) => {
  const { length, active } = props

  const dotItems = []

  for (let i = 0; i < length; i++) {
    dotItems.push({
      active: active === i
    })
  }

  return (
    <div className={style.dots}>
      <ul className={style.dotList}>
        {dotItems.map((item, index) => (
          <li
            key={index}
            className={classNames(style.dotItem, {
              [style["dotItem--active"]]: item.active
            })}
          />
        ))}
      </ul>
    </div>
  )
}

export default Dots
