import React from "react"
import style from "./style.scss"

interface SplashScreenProps {}

const SplashScreen: React.SFC<Readonly<SplashScreenProps>> = (props) => {
  return (
    <div className={style.splash}>
      <div className={style.splashOverlay} />
      <div className={style.container}>
        <div className={style.iconBlack}>
          <img src="/static/images/Black.svg"></img>
        </div>
        <div className={style.iconMirror}>
          <img src="/static/images/Mirror.svg"></img>
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
