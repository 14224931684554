import useSlider from "@/components/util/useSlider"
import { HTMLElementProps, StylesProps } from "@/types/props"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import SliderItem, { SliderItemProps } from "./SliderItem"
import baseStyles from "./style.scss"

interface SliderProps extends HTMLElementProps {
  title?: string
  style: StylesProps
  autoplay: boolean
  slides: Array<Partial<SliderItemProps>>
  speed: number
  dots?: boolean
  draggable?: boolean
}

const defaultProps = {
  dots: false,
  draggable: false
}

const Slider: React.SFC<Readonly<SliderProps>> = (props) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const { slides, autoplay, speed, style, dots, draggable, title } = props

  useEffect(() => {
    setLoaded(true)
  }, [])

  const {
    activeSlide,
    setNextSlide,
    handleTouch,
    handleTouchStart
  } = useSlider({
    speed,
    autoplay,
    slides,
    draggable: draggable || defaultProps.draggable
  })

  return (
    slides && (
      <section
        className={classNames(style.slider, {
          [style["slider--loaded"]]: loaded
        })}
      >
        {title && (
          <header className={baseStyles.header}>
            <h1>{title}</h1>
          </header>
        )}
        <div className={style.slideList}>
          {slides.map((slide, index) => {
            const isActive = activeSlide && activeSlide.index === index
            const wasActive = activeSlide && activeSlide.index! > index

            const slideActiveStep = isActive
              ? activeSlide.step
              : wasActive && slide.steps
              ? slide.steps.length - 1
              : 0

            return (
              <SliderItem
                {...slide}
                key={index}
                index={index}
                dots={dots || defaultProps.dots}
                style={style}
                active={isActive}
                activeStep={slideActiveStep}
                setNextSlide={setNextSlide}
                handleTouch={handleTouch}
                handleTouchStart={handleTouchStart}
              />
            )
          })}
        </div>
      </section>
    )
  )
}

export default Slider
