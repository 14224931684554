import { useEffect, useState } from "react"

interface Component<C, D> {
  component: C
  data?: D
}

type TextComponent = Component<
  "Text",
  {
    body: string
  }
>

type ImageComponent = Component<
  "Image",
  {
    image?: {
      alt: string
      url: string
      size: "Full" | "Large" | "Medium" | "Small"
    }
  }
>

type ActionComponent = Component<
  "Action",
  {
    actions?: Array<{
      type: string
      text: string
    }>
  }
>

enum SliderItemSteps {
  USER = "USER",
  AUTO = "AUTO"
}

export interface SliderItem {
  index: number
  step?: number
  steps?: SliderItemSteps[]
  content?: Array<
    | TextComponent
    | ImageComponent
    | ActionComponent
    | Component<"ChoiceSlider", {}>
    | Component<"Dial", {}>
  >
  description?: Array<{
    step: number
    content: Array<TextComponent | ImageComponent | ActionComponent>
  }>
}

export interface SliderOptions<T> {
  slides: Array<Partial<SliderItem & T>>
  speed?: number
  autoplay?: boolean
  draggable?: boolean
}

interface SliderState<T> {
  activeSlide: Partial<SliderItem & T>
  paused: boolean
  setNextSlide: () => void
  handleTouchStart: (event: any) => void
  handleTouch: (event: any) => void
}

function useSlider<T>(options: SliderOptions<T>): SliderState<T> {
  const { speed, autoplay, slides, draggable } = options
  const [activeSlide, setActiveSlide] = useState<any>()
  const [timeoutRef, setTimeoutRef] = useState<any>()
  const [paused, setPaused] = useState<boolean>(true)
  const [touchToSlide, setTouchToSlide] = useState<boolean>(false)
  const [touchPos, setTouchPos] = useState<{ start: number; end: number }>({
    start: 0,
    end: 0
  })

  useEffect(() => {
    setActiveSlide({
      ...slides[0],
      index: 0,
      step: 0
    })

    setPaused(!autoplay)
  }, [])

  useEffect(() => {
    if (!paused && activeSlide) {
      const stepCount = activeSlide.steps ? activeSlide.steps.length - 1 : 0

      const nextSlide =
        activeSlide.step < stepCount
          ? {
              ...slides[activeSlide.index],
              index: activeSlide.index,
              step: activeSlide.step + 1
            }
          : {
              ...slides[activeSlide.index + 1],
              index: activeSlide.index + 1,
              step: 0
            }

      if (isSlideControlled()) {
        clearTimeout(timeoutRef)
        setPaused(true)
        return
      }

      handleNextSlide(nextSlide)
    }
  }, [activeSlide])

  useEffect(() => {
    const distanceThrottle = Math.abs(touchPos.start - touchPos.end)

    if (!touchToSlide && distanceThrottle > 20) {
      if (!isSlideControlled()) {
        clearTimeout(timeoutRef)
        setActiveSlide({
          ...slides[activeSlide.index + 1],
          index: activeSlide.index + 1,
          step: 0
        })
        setTouchToSlide(true)
      }
    }
  }, [touchPos])

  const isSlideControlled = () => {
    return (
      activeSlide.steps &&
      activeSlide.steps[activeSlide.step] === SliderItemSteps.USER
    )
  }

  const handleNextSlide = (nextSlide: SliderItem) => {
    if (autoplay && nextSlide.index < slides.length) {
      const timeout = setTimeout(() => {
        setActiveSlide(nextSlide)
      }, speed)

      setTimeoutRef(timeout)
    } else {
      setPaused(true)
    }
  }

  const setNextSlide = () => {
    const stepCount = activeSlide.steps ? activeSlide.steps.length - 1 : 0
    setPaused(false)

    if (activeSlide.step < stepCount) {
      setActiveSlide({
        ...slides[activeSlide.index],
        index: activeSlide.index,
        step: activeSlide.step + 1
      })
    } else {
      setActiveSlide({
        ...slides[activeSlide.index + 1],
        index: activeSlide.index + 1,
        step: 0
      })
    }
  }

  const handleTouchStart = (event: any) => {
    setTouchToSlide(false)
    setTouchPos({
      start: event.touches[0].clientX,
      end: event.touches[0].clientX
    })
  }

  const handleTouch = (event: any) => {
    if (draggable && activeSlide.index + 1 < slides.length) {
      setTouchPos({ start: touchPos.start, end: event.touches[0].clientX })
    }
  }

  return {
    activeSlide: activeSlide!,
    paused,
    setNextSlide,
    handleTouchStart,
    handleTouch
  }
}

export default useSlider
