import Typography from "@/components/foundation/typography"
import React from "react"
import style from "./style.scss"

interface ChoiceSliderItemProps {
  image: any
  title: string
}

const ChoiceSliderItem: React.SFC<Readonly<ChoiceSliderItemProps>> = (
  props
) => {
  return (
    <article className={style.choiceSliderItem}>
      <header className={style.header}>
        <Typography.H3 as="h1" className={style.title}>
          {props.title}
        </Typography.H3>
      </header>

      <figure className={style.image}>
        <img src={props.image} />
      </figure>
    </article>
  )
}

export default ChoiceSliderItem
