import Button from "@/components/Button"
import useImageTransform from "@/components/util/useImageTransform"
import { mapDispatchToProps } from "@/lib/withReduxStore"
import globalActions from "@/store/globals/actions"
import { getFileDuration } from "@/store/util/file"
import classNames from "classnames"
import React from "react"
import { connect } from "react-redux"
import { isLessThanOrEqualTo } from "../util/form/validators"
import style from "./style.scss"

interface MediaCaptureProps {
  title?: string
  name?: string
  className?: string
  accept?: string
  capture?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, file: File) => void
  dispatch: any
}

const MediaCapture: React.SFC<Readonly<MediaCaptureProps>> = (props) => {
  const {
    title = "Take Video",
    name = "mediacapture",
    className,
    accept = "video/*",
    capture,
    onChange = () => {}
  } = props

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]

      if (accept === "image/*") {
        useImageTransform(file).then((payload) => {
          onChange(e, payload)
        })
        return
      }

      if (accept === "video/*") {
        getFileDuration(file)
          .then((duration) => {
            if (duration > 60) {
              throw new Error(
                "The video you're trying to add is too long. <br/> Please add a video that fits the guidelines."
              )
            }

            onChange(e, file)
          })
          .catch((err) => {
            props.dispatch(globalActions.pushError(err))
          })

        return
      }

      onChange(e, file)
    }
  }

  return (
    <div className={classNames(style.mediaCapture, className)}>
      <Button tagName="label" label={title}>
        <input
          type="file"
          name={name}
          accept={accept}
          capture={capture}
          onChange={handleChange}
        />
      </Button>
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(MediaCapture)
