import { dataUriToFile } from "@/store/util/file"
import loadImage, { LoadImageOptions } from "blueimp-load-image"

const useImageTransform = (
  image: File,
  config: LoadImageOptions = { canvas: true, orientation: true }
): Promise<File> => {
  return new Promise((resolve) => {
    loadImage(
      image,
      (canvas) => {
        resolve(
          dataUriToFile(
            (canvas as HTMLCanvasElement).toDataURL("image/jpeg", 0.75),
            image.name,
            image
          )
        )
      },
      config
    )
  })
}

export default useImageTransform
