import React, { useEffect, useState } from "react"

export interface RotatableOptions {
  ref: React.RefObject<any>
  disabled: boolean
  angleOptions: {
    defaultAngle: number
    offset: number
    constraints: {
      min: number
      max: number
    }
  }
}

interface RotatableState {
  onDragStart: () => void
  onDragEnd: () => void
  onDrag: (e: any) => void
  angle: number
  progress: number
  dragging: boolean
}

const useRotatable = (options: RotatableOptions): RotatableState => {
  const { ref, angleOptions, disabled } = options
  const [angle, setAngle] = useState<number>(angleOptions.defaultAngle)
  const [progress, setProgress] = useState<number>(0)
  const [dragging, setDragging] = useState<boolean>(false)

  useEffect(() => {
    if (disabled) {
      setDragging(false)
    }
  }, [disabled])

  const getOffsetTop = (element: any) => {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent
    }
    return offsetTop
  }

  const onDragStart = () => {
    if (!disabled) {
      setDragging(true)
    }
  }

  const onDragEnd = () => {
    if (!disabled) {
      setDragging(false)
    }
  }

  const onDrag = (e: any) => {
    if (!dragging || disabled) {
      return
    }

    const offset = {
      x: ref.current.offsetLeft,
      y: getOffsetTop(ref.current)
    }

    const center = {
      x: 50,
      y: 50
    }

    const x =
      ((e.type === "touchmove"
        ? e.touches[0].clientX - offset.x
        : e.clientX - offset.x) /
        ref.current.offsetWidth) *
      100

    const y =
      ((e.type === "touchmove"
        ? e.touches[0].clientY - offset.y
        : e.clientY - offset.y) /
        ref.current.offsetHeight) *
      100

    const newAngle = (Math.atan2(center.y - y, center.x - x) * 180) / Math.PI

    const arc =
      360 + angleOptions.constraints.max - angleOptions.constraints.min

    const percentProgress = Math.round(
      (newAngle >= angleOptions.offset
        ? (newAngle - angleOptions.offset) / arc
        : (360 + newAngle - angleOptions.offset) / arc) * 100
    )

    if (
      newAngle >= angleOptions.constraints.max &&
      newAngle < angleOptions.constraints.min
    ) {
      const deadzonePercent = Math.abs(
        Math.round(((newAngle - angleOptions.offset) / (360 - arc)) * 100)
      )

      if (deadzonePercent < 50 && angle !== angleOptions.constraints.min) {
        setAngle(angleOptions.constraints.min)
        setProgress(0)
        return
      }

      if (deadzonePercent > 50 && angle !== angleOptions.constraints.max) {
        setAngle(angleOptions.constraints.max)
        setProgress(100)
        return
      }

      return
    }

    setAngle(newAngle)
    setProgress(percentProgress)
  }

  return {
    onDragStart,
    onDragEnd,
    onDrag,
    angle,
    progress,
    dragging
  }
}

export default useRotatable
